import React, { useCallback, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import { TabButtons } from 'ui-lib/components/TabButtons'
import {
	Label,
	P,
	H2,
	TableTitle,
	CircleBox,
} from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Spacer } from 'ui-lib/utils/Spacer'
import { applyMediaQueries, getSummaryTree } from 'ui-lib/utils/helpers'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { TraceSummary } from 'ui-lib/components/Trace-Summary'
import { TraceTree } from 'ui-lib/components/Trace-Tree'
import { TraceGraph } from 'ui-lib/components/Trace-Graph'
import { TraceMaps } from 'ui-lib/components/TraceMaps'
import { TraceDeforestationEvents } from 'ui-lib/components/TraceDeforestationEvents'
import { Modal } from 'ui-lib/components/Modal'
import { getDateByFormat } from 'core-app/utils/date'
import { useTranslation } from 'react-i18next'
import verifiedIcon from 'ui-lib/icons/verified.svg'
import SuccessIcon from 'ui-lib/icons/success-round.svg'
import DownloadIcon from 'ui-lib/icons/file_download_black_24dp.svg'
import { useReactToPrint } from 'react-to-print'
import Html2Pdf from 'html2pdf.js'
import { Drop } from 'grommet'
import {
	Button,
	ButtonIconWrapper,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { FullScreen } from 'ui-lib/components/FullScreen'
import FullScreenIcon from 'ui-lib/icons/fullscreen.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import NotVerifiedIcon from 'ui-lib/icons/not_verified.svg'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import theme from 'ui-lib/utils/base-theme'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { CheckBoxGroup } from 'ui-lib/components/Checkbox/index'
import { AreaMapPreview } from 'ui-lib/components/AreaMapPreview'
import moment from 'moment'
import {
	EUDR_COMPLIANCE_LIST,
	EUDR_COMPLIANCE_LIST_LABELS,
	EUDR_COMPLIANCE_LIST_FOR_SUMMARY,
} from '../config'
import { TraceDuc } from '../duc'

const TABLE_ALIAS = {
	createdAt: 'productTrace.createdAt',
	product: 'productTrace.product',
	quantity: 'productTrace.quantity',
	consignmentNumber: 'productTrace.consignmentNumber',
	transporter: 'productTrace.transporter',
	certificate: 'productTrace.certificate',
	supplyChainModel: 'productTrace.supplyChainModel',
	from: 'productTrace.from',
	to: 'productTrace.to',
	deforestArea: 'productTrace.deforestArea',
	millScore: 'productTrace.millScore',
}

const SegmentSeparatorWrapper = styled.div({
	position: 'relative',
	height: 'inherit',
})

const SegmentSeparatorLine = styled.div({
	height: '100%',
	width: 'auto',
	border: '1px solid #eae9e9',
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	...applyMediaQueries(['mobile'], {
		height: 'auto',
		width: '100%',
	}),
})

const Heading = styled(H2)({
	marginBottom: 0,
})

const DeforestationLink = styled(Label)({
	textDecoration: 'underLine',
	marginLeft: '20px',
	'&:hover': {
		color: '#000',
	},
})

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const PdfBaseContainer = styled(Box)({
	display: 'none',
})

const PdfContentContainer = styled(Box)({
	margin: '60px 20px 30px 30px',
})

const PdfDataContainer = styled(Box)({
	pageBreakAfter: 'always',
})

const PdfBoxShadowContainer = styled(Box)({
	marginTop: '100px',
})

const PdfTreeContainer = styled(Box)({
	overflow: 'auto',
	marginTop: '100px',
	margin: '50px 20px 30px 30px',
})

const PdfTreeHeader = styled(Box)({
	fontWeight: 500,
	fontSize: '20px',
	paddingBottom: '10px',
})

const PdfTree = styled(Box)({
	padding: '8px',
	marginLeft: '100px',
})

const PdfDownloadButtonContainer = styled(Box)({
	alignItems: 'center',
})

const PdfDownloadButtonWrapper = styled(Box)({
	width: '300px',
	minWidth: '220px',
	marginBottom: '24px',
})

const SummaryBlock = ({
	name,
	detail,
	tagline,
	icon,
	status,
	tooltip,
	tooltipMsg,
	detailStyle,
}) => {
	const [tooltipToggle, setTooltipToggle] = useState(true)
	const ref = useRef()

	return (
		<SummaryWrapper
			style={{ cursor: tooltip ? 'pointer' : '' }}
			onClick={() => tooltip && setTooltipToggle(!tooltipToggle)}
		>
			<Box row alignItems="center">
				{icon && (
					<>
						<IconWrapper color="green" size={20} ref={ref}>
							<Icon glyph={icon} />
						</IconWrapper>
						<Spacer size={8} horizontal />
						{tooltipToggle && tooltip && (
							<Drop
								target={ref.current}
								style={{
									background: 'none',
									boxShadow: 'none',
									border: 'none',
								}}
							>
								<Box
									style={{
										background: '#333',
										width: '99%',
										padding: 16,
										maxWidth: 300,
										marginTop: 24,
									}}
								>
									{tooltipMsg && tooltipMsg !== '' && (
										<Label
											color="#fff"
											small
											key={tooltipMsg}
											style={{
												borderLeft: '3px solid #e07616',
												paddingLeft: 16,
												cursor: 'inherit',
											}}
										>
											{tooltipMsg}
										</Label>
									)}
								</Box>
							</Drop>
						)}
					</>
				)}

				<Label color="#263b97" style={{ cursor: 'inherit' }}>
					{name}:
				</Label>
				<Spacer size={16} horizontal />
				<Label
					color={status || '#000000'}
					style={detailStyle || { cursor: 'inherit' }}
					title={detail}
				>
					{detail}
				</Label>
				{tagline && (
					<>
						<Spacer size={12} horizontal />
						<P small>{tagline}</P>
					</>
				)}
			</Box>
		</SummaryWrapper>
	)
}

const KeyValueSegmentWithClickableLink = ({ name, detail }) => {
	return (
		<>
			<Box row padding="2px 0px">
				<Box width={170}>
					<Label color="#263b97" bold>
						{name}
					</Label>
				</Box>
				<Box style={{ marginLeft: 10 }} flex row>
					{detail.map((item, index) => (
						<>
							<Label
								style={{
									color: item.certURL
										? 'rgb(38, 59, 151)'
										: theme.color.black,
									textDecoration: item.certURL
										? 'underline'
										: 'none',
								}}
								link={item.certURL}
								onClick={() => {
									if (item.certURL)
										window.open(item.certURL, '_blank')
								}}
							>
								{`${item.ctype}${
									index !== detail.length - 1 ? ', ' : ''
								}`}
							</Label>
							<Spacer size={4} horizontal />
						</>
					))}
				</Box>
			</Box>
			<Spacer size={8} />
		</>
	)
}

const KeyValueSegment = ({ name, detail, openlink, sub = false }) => {
	const { t } = useTranslation()

	return (
		<>
			<Box row padding="2px 0px">
				<Box width={sub ? 150 : 170}>
					<Label color={sub ? '#7c88bd' : '#263b97'} bold>
						{name}
					</Label>
				</Box>
				<Box style={{ marginLeft: 10 }} flex row>
					<P large>{detail}</P>
					{openlink && (
						<>
							<Spacer size={16} horizontal />
							<Label
								style={{
									color: 'rgb(38,59,151)',
								}}
								link
								onClick={() => {
									const [long, lat] = detail.split(',')
									window.open(
										`https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
										'_blank'
									)
								}}
							>
								{t('productTrace.viewOnMap')}
							</Label>
						</>
					)}
				</Box>
			</Box>
			{!sub && <Spacer size={8} />}
		</>
	)
}

export const traceDeforestationDataTableConfig = t => {
	return [
		{
			property: 'startPeriod',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.from)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { startPeriod } = datum

				if (startPeriod) return <Label as="span">{startPeriod}</Label>

				return 'N/A'
			},
		},
		{
			property: 'endPeriod',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.to)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { endPeriod } = datum

				if (endPeriod) return <Label as="span">{endPeriod}</Label>

				return 'N/A'
			},
		},
		{
			property: 'deforestArea',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.deforestArea)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { deforestArea } = datum

				if (deforestArea) return <Label as="span">{deforestArea}</Label>

				return 'N/A'
			},
		},
		{
			property: 'deforestScore',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.millScore)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { deforestScore } = datum

				if (deforestScore)
					return <Label as="span">{deforestScore}</Label>

				return 'N/A'
			},
		},
	]
}

export const traceTableConfig = t => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { product } = datum

				if (product) return <Label as="span">{product}</Label>

				return 'N/A'
			},
		},
		{
			property: 'supplyChainModel',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.supplyChainModel)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { supplyChainModel } = datum

				if (supplyChainModel?.name)
					return <Label as="span">{supplyChainModel.name}</Label>

				return 'N/A'
			},
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { quantity } = datum

				if (quantity) return <Label as="span">{quantity}</Label>

				return 'N/A'
			},
		},
		{
			property: 'lcv',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>LCA</TableTitle>
				</Box>
			),
			render: datum => {
				const { lcv } = datum

				return <Label as="span">{lcv}</Label>
			},
		},
		{
			property: 'blockchainVerification',
			render: datum => {
				const { blockChainFailed } = datum

				if (blockChainFailed)
					return (
						<IconWrapper size={25} color="#D05858">
							<Icon glyph={NotVerifiedIcon} />
						</IconWrapper>
					)
			},
		},
		{
			property: 'status',
			render: datum => {
				const { status } = datum

				if (status === 'success')
					return (
						<IconWrapper color="green" size={20}>
							<Icon glyph={SuccessIcon} />
						</IconWrapper>
					)
			},
		},
	]
}

const GenerateTrace = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const componentRef = useRef()

	const treeRecord = useSelector(TraceDuc.selectors.getActiveTraceTreeRecord)
	const deforestationOrgCount = useSelector(
		TraceDuc.selectors.getDeforestationOrgCount
	)

	const hederaMessages = useSelector(TraceDuc.selectors.getHederaMessages)
	const deforestationEvents = useSelector(
		TraceDuc.selectors.getDeforestationEvents
	)
	const initialOrgName = getIn(treeRecord, [0, 'title']) || ''

	const entityOrg = getIn(treeRecord, [0, 'transaction', 0, 'entityID']) || ''
	const entityNumber =
		getIn(treeRecord, [0, 'transaction', 0, 'entityNumber']) || ''

	const partnerName = getIn(treeRecord, [0, 'children', 0, 'title']) || ''
	const productName =
		getIn(treeRecord, [0, 'transaction', 0, 'product']) || ''
	const quantity = getIn(treeRecord, [0, 'transaction', 0, 'quantity']) || ''
	const entityIssuedDate =
		getIn(treeRecord, [0, 'transaction', 0, 'createdAt']) || ''

	const graphRecord = useSelector(
		TraceDuc.selectors.getActiveTraceGraphRecord
	)

	const loadingState = useSelector(
		TraceDuc.selectors.getProductTraceLoadingState
	)

	const _graphRecord = Object.assign({}, graphRecord)
	const [orgName, setActiveOrgName] = useState(initialOrgName)
	const [certType, setCertType] = useState([])
	const [currentDeforestationData, setCurrentDeforestationData] = useState([])
	const [isPDF, setIsPDF] = useState(false)
	const [showDeforestationModal, setShowDeforestationModal] = useState(false)
	const [selectedDeforestOrg, setSelectedDeforestOrg] = useState([])

	const canShowDeforestation = ['palmoil-smallholder', 'palmoil-plantation']
	const intitalDocument = getIn(treeRecord, [0, 'transaction']) || []
	const intitalTransformedDocument =
		getIn(treeRecord, [0, 'transformed']) || []
	const intitalTransformingDocument =
		getIn(treeRecord, [0, 'transforming']) || []

	const mapRecord = useSelector(TraceDuc.selectors.getActiveTraceMapRecord)

	const [orgInfo, setOrgInfo] = useState({})
	const [documents, setDocuments] = useState(intitalDocument)
	const [transformedDocuments, setTransformedDocuments] = useState(
		intitalTransformedDocument
	)
	const [transformingDocuments, setTransformingDocuments] = useState(
		intitalTransformingDocument
	)

	const [polygonData, setPolygonData] = useState({})
	const [showMap, setShowMap] = useState(false)
	const [activeStatus, setActiveStatus] = useState('trace-summary')
	const badNodePaths = useSelector(TraceDuc.selectors.getBadNodePaths)
	const [
		currentCertificatesSelected,
		setCurrentCertificatesSelected,
	] = useState([])

	const [
		currentSupplyChainSelected,
		setCurrentSupplyChainSelected,
	] = useState([])

	const [nodeHasDetail, setNodeHasDetail] = useState(true)

	const currentCertificates =
		treeRecord && treeRecord.length > 0
			? getIn(treeRecord, [0, 'certificateTypes'])
			: []

	const currentSupplyChain =
		treeRecord && treeRecord.length > 0
			? getIn(treeRecord, [0, 'supplyChainTypes'])
			: []

	const isNeeded = false

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'product-trace') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PRODUCT_TRACE
				)
			)
		}
	}

	const handleOnChange = useCallback((actionType, meta = {}) => {
		switch (actionType) {
			case 'apply_filter': {
				const { filterValue } = meta
				setActiveStatus(filterValue)
				break
			}
			case 'on_node_click': {
				const {
					transforming,
					transformed,
					transaction,
					currentOrgDetail,
					deforestationData,
					noDotColorStatus,
				} = meta
				const uniqueTransaction = transaction
					? [
							...new Map(
								transaction.map(item => [item.traceID, item])
							).values(),
					  ]
					: []
				const uniqueTransforming = transforming
					? [
							...new Map(
								transforming.map(item => [item.traceID, item])
							).values(),
					  ]
					: []
				const uniqueTransformed = transformed
					? [
							...new Map(
								transformed.map(item => [item.traceID, item])
							).values(),
					  ]
					: []
				setNodeHasDetail(!noDotColorStatus)
				setDocuments(uniqueTransaction)
				setTransformedDocuments(uniqueTransforming)
				setTransformingDocuments(uniqueTransformed)
				setOrgInfo(currentOrgDetail)
				setCurrentDeforestationData(deforestationData)
				break
			}

			default:
				break
		}
	}, [])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.productTrace'),
			name: 'product-trace',
			isActive: true,
		},
		{
			label: t('breadcrumb.generateTrace'),
			name: 'generate-trace',
			isActive: false,
		},
	]

	const FILTERS_STATUS_CONFIG = [
		{
			name: 'trace-summary',
			label: t('productTrace.summary'),
		},
		{
			name: 'trace-tree',
			label: t('productTrace.tree'),
		},
		{
			name: 'trace-graph',
			label: t('productTrace.graph'),
		},
		{
			name: 'trace-map',
			label: t('productTrace.map'),
		},
	]

	const coordinates = []
	mapRecord.forEach(coordinate => {
		if (
			!Number.isNaN(coordinate.coordinates[0]) &&
			!(
				coordinate.coordinates[0] === 0 &&
				coordinate.coordinates[1] === 0
			)
		) {
			coordinates.push(coordinate.coordinates)
		}
	})

	useEffect(() => {
		let trasformingUpdated = transformedDocuments
		if (transformedDocuments.length > 0 && hederaMessages.data.length > 0) {
			trasformingUpdated = transformedDocuments.map(item => ({
				...item,
				status: hederaMessages.data.find(
					message => message.docReferenceID === item.traceID
				)
					? 'success'
					: '',
			}))
		}
		setTransformedDocuments(trasformingUpdated)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hederaMessages])

	useEffect(() => {
		if (currentCertificates.length > 0) {
			setCurrentCertificatesSelected([currentCertificates[0].key])
		}
	}, [currentCertificates])

	useEffect(() => {
		if (currentSupplyChain.length > 0) {
			setCurrentSupplyChainSelected(currentSupplyChain[0].name)
		}
	}, [currentSupplyChain])

	const getSummaryBlock = () => (
		<Box row wrap justifyContent="space-between">
			<SummaryBlock
				name={t('productTrace.companyName')}
				detail={orgName}
				detailStyle={{
					width: 225,
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}}
			/>
		</Box>
	)

	const getCertificateType = (pdf = false) => {
		return (
			<>
				{currentCertificates.length > 0 && (
					<Box
						style={{
							padding: 8,
							float: 'left',
							minHeight: 74,
							borderRadius: 5,
							overflow: 'auto',
							margin: !pdf ? '15px 15px 0 0' : '0px 0px 20px 0px',
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<P
							bold
							color="#263b97"
							style={{
								marginBottom: 10,
							}}
						>
							{t('productTrace.verifyByCertificateType')}
						</P>
						<CheckBoxGroup
							direction="row"
							selected={currentCertificatesSelected}
							options={currentCertificates}
							onChange={value => {
								setCurrentCertificatesSelected(value)
							}}
						/>
					</Box>
				)}
			</>
		)
	}

	const getSupplyChainType = (pdf = false) => {
		return (
			<>
				{currentSupplyChain.length > 0 && (
					<Box
						style={{
							padding: 8,
							float: 'left',
							minHeight: 74,
							borderRadius: 5,
							overflow: 'auto',
							margin: !pdf ? '15px 15px 0 0' : '0px',
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<P
							bold
							color="#263b97"
							style={{
								marginBottom: 10,
							}}
						>
							{t('productTrace.verifyBySupplyChain')}
						</P>
						<RadioInputGroup
							hideError
							direction="row"
							options={currentSupplyChain}
							selected={currentSupplyChainSelected}
							onChange={e => {
								setCurrentSupplyChainSelected(e.target.value)
							}}
						/>
					</Box>
				)}
			</>
		)
	}

	const getTraceSummary = () => (
		<TraceSummary
			track={{
				badNodePaths,
				nodes: getSummaryTree(treeRecord, t),
			}}
			certificatesSelected={currentCertificatesSelected}
			supplyChainSelected={currentSupplyChainSelected}
			expandDepth={0}
			onClick={data => {
				setCertType(data.certType)
				setActiveOrgName(data.title)
				if (data.transforming && data.transforming.length > 0) {
					const tracevalueList = data.transforming.map(
						transforming => transforming.traceID
					)
					const traceIDs = tracevalueList.join(',')
					dispatch(TraceDuc.creators.getHederaMessages(traceIDs))
				}

				handleOnChange('on_node_click', {
					nodeId: data.id,
					transaction: data.transaction,
					transforming: data.transforming,
					transformed: data.transformed,
					currentOrgDetail: data.currentOrgDetail,
					deforestationData: data.deforestationData,
					noDotColorStatus: data.noDotColorStatus,
				})
			}}
		/>
	)

	const labelTexts = {
		addressText: t('productTrace.address'),
		locationText: t('productTrace.location'),
		certTypeText: t('productTrace.certificationType'),
		qtyText: t('productTrace.quantity'),
	}

	const getTraceTree = (pdf = false) => (
		<TraceTree
			track={{
				badNodePaths,
				nodes: treeRecord,
			}}
			certificatesSelected={currentCertificatesSelected}
			supplyChainSelected={currentSupplyChainSelected}
			expandDepth={0}
			onClick={data => {
				setActiveOrgName(data.title)
				setCertType(data.certType)
				handleOnChange('on_node_click', {
					nodeId: data.id,
					transaction: data.transaction,
					transforming: data.transforming,
					transformed: data.transformed,
					currentOrgDetail: data.currentOrgDetail,
					deforestationData: data.deforestationData,
				})
				if (data.transforming && data.transforming.length > 0) {
					const tracevalueList = data.transforming.map(
						transforming => transforming.traceID
					)
					const traceIDs = tracevalueList.join(',')
					dispatch(TraceDuc.creators.getHederaMessages(traceIDs))
				}
			}}
			isPDF={!!pdf}
			labelTexts={labelTexts}
		/>
	)

	const getEudrLabels = () => {
		const eudrLabels = []

		EUDR_COMPLIANCE_LIST.forEach(item => {
			eudrLabels.push({
				id: item,
				label: t(EUDR_COMPLIANCE_LIST_LABELS[item]),
			})
		})

		return eudrLabels
	}

	const getTraceGraph = (isFullScreen = false) => (
		<Box
			style={{
				overflow: 'auto',
				// transform: 'scale(-1,1)',
				backgroundColor: theme.color.grey11,
			}}
			padding={8}
		>
			<TraceGraph
				data={{
					badNodePaths,
					nodes: _graphRecord,
				}}
				isFullScreen={isFullScreen}
				certificatesSelected={currentCertificatesSelected}
				supplyChainSelected={currentSupplyChainSelected}
				onClick={data => {
					setActiveOrgName(data.title)
					handleOnChange('on_node_click', {
						nodeId: data.id,
						transaction: data.transaction,
						transforming: data.transforming,
						transformed: data.transformed,
					})
				}}
				eudrDetails={getEudrLabels()}
			/>
		</Box>
	)

	const getTraceMap = () => (
		<TraceMaps
			coordinatesets={mapRecord}
			treeData={treeRecord}
			height={650}
			onClick={data => {
				setActiveOrgName(data.name)
				handleOnChange('on_node_click', {
					nodeId: data.name,
					transforming: [data],
					transformed: [],
				})
			}}
			eudrDetails={getEudrLabels()}
			onShowDeforestation={_orgid => {
				const orgDetails = mapRecord
					.filter(f => {
						const { orgID = '' } = f

						return orgID === _orgid
					})
					.reduce((accumulator, o) => {
						const existingOrg = accumulator.find(
							entry => entry.value === o.orgID
						)

						if (!existingOrg) {
							accumulator.push({
								label: o.name,
								value: o.orgID,
								orgTypeID: o.orgTypeID,
								address: o.locationOfTrace,
							})
						}

						return accumulator
					}, [])
				setSelectedDeforestOrg(orgDetails)
				dispatch(TraceDuc.creators.getDeforestationEvents(_orgid))
				setShowDeforestationModal(true)
			}}
			t={t}
		/>
	)

	const getMapNotAvailable = () => (
		<Box alignItems="center" justifyContent="center" padding={8}>
			<P>{t('productTrace.mapNotAvailable')}</P>
		</Box>
	)

	const getOrgInfo = () => {
		const orgID = getIn(orgInfo, ['orgID']) || ''
		const geoData = getIn(documents, [0, 'location', 'geoData']) || {}

		return (
			<BoxShadowWrapper>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<P bold color="#263b97">
						{t('productTrace.orgInfo')}
					</P>
				</Box>

				<Box padding={8}>
					<KeyValueSegmentWithClickableLink
						name={t('productTrace.certificationType')}
						detail={getIn(documents, [0, 'certType']) || []}
					/>
					<KeyValueSegment
						name={t('productTrace.address')}
						detail={
							getIn(documents, [0, 'locationOfTrace']) ||
							t('common.notAvailable')
						}
					/>
					{!(geoData?.type && orgID) && (
						<KeyValueSegment
							name={t('productTrace.location')}
							detail={
								getIn(documents, [0, 'location', 'long'])
									? `${getIn(documents, [
											0,
											'location',
											'long',
									  ])} , ${getIn(documents, [
											0,
											'location',
											'lat',
									  ])}`
									: t('common.notAvailable')
							}
							openlink={
								getIn(documents, [0, 'location', 'long']) || ''
							}
						/>
					)}
					{geoData?.type && orgID && (
						<KeyValueSegment
							name={t('productTrace.location')}
							detail={
								<Label
									style={{
										color: 'rgb(38,59,151)',
									}}
									link
									onClick={() => {
										setPolygonData(geoData)
										setShowMap(true)
									}}
								>
									{t('productTrace.viewPolygonOnMap')}
								</Label>
							}
						/>
					)}
					<KeyValueSegment
						name={t('productTrace.product')}
						detail={
							getIn(documents, [0, 'product']) ||
							t('common.notAvailable')
						}
					/>
					<KeyValueSegment
						name={t('productTrace.totalQuantity')}
						detail={
							getIn(documents, [0, 'totalQuantity']) ||
							t('common.notAvailable')
						}
					/>
					<KeyValueSegment
						name={`${t('production.total')} LCA`}
						detail={getIn(documents, [0, 'totalLCV'])}
					/>
				</Box>
			</BoxShadowWrapper>
		)
	}

	const getTransformingDocuments = () => {
		return (
			<>
				<Spacer size={24} />
				<Box row justifyContent="space-between">
					<Label>{t('productTrace.producedGoods')}</Label>
				</Box>
				<Spacer size={8} />
				<BoxShadowTable
					columnConfig={traceTableConfig(t)}
					rowData={transformingDocuments}
					loading={loadingState}
				/>
			</>
		)
	}

	const getTransformedDocuments = () => {
		return (
			<>
				<Spacer size={24} />
				<Box row justifyContent="space-between">
					<Label>{t('productTrace.transactionsList')}</Label>
				</Box>
				<Spacer size={8} />

				<BoxShadowTable
					columnConfig={traceTableConfig(t)}
					rowData={transformedDocuments}
					loading={loadingState}
				/>
			</>
		)
	}

	const getTabButtons = () => (
		<Box>
			<TabButtons
				id="product"
				list={FILTERS_STATUS_CONFIG}
				activeIndex={FILTERS_STATUS_CONFIG.findIndex(
					q => q.name === activeStatus
				)}
				onChange={({ name }) =>
					handleOnChange('apply_filter', {
						filterValue: name,
						filterType: 'status',
					})
				}
			/>
		</Box>
	)

	const handlePrintToPDF = useReactToPrint({
		content: () => componentRef.current,
		onBeforeGetContent: () => setIsPDF(true),
		onAfterPrint: () => {
			setIsPDF(false)
		},
		print: async printIframe => {
			const document = printIframe.contentDocument
			if (document) {
				const html = document.getElementsByTagName('html')[0]
				setTimeout(() => {
					Html2Pdf(html, {
						filename: `productTrace-${entityNumber}.pdf`,
						image: { type: 'svg', quality: 0.98 },
						html2canvas: {
							scale: 2,
							logging: true,
							dpi: 192,
							letterRendering: true,
						},
						jsPDF: {
							unit: 'px',
							format: 'a4',
							orientation: 'portrait',
							hotfixes: ['px_scaling'],
							compress: true,
						},
					})
				}, 50)
			}
		},
	})

	const getTitleForPDF = () => {
		return (
			<Box
				style={{
					fontWeight: 500,
					fontSize: '26px',
					paddingBottom: '3px',
				}}
			>
				{`${t('productTrace.title')} - ${entityNumber}`}
			</Box>
		)
	}

	const getSubTitleForPDF = () => {
		return (
			<Box
				style={{
					fontWeight: 300,
					fontSize: '14px',
					paddingBottom: '30px',
				}}
			>
				{`${t('productTrace.showingTraceOf')} ${partnerName}`}
			</Box>
		)
	}

	const getDownloadButton = () => {
		return (
			<PdfDownloadButtonContainer>
				<PdfDownloadButtonWrapper>
					<Button
						label={
							<div
								style={{
									marginLeft: '7px',
								}}
							>
								{`${t('productTrace.download')} ${t(
									'productTrace.tree'
								)}`}
							</div>
						}
						extendStyles={{
							minWidth: '220px',
							paddingRight: '12px',
							color: theme.color.blue8,
							backgroundColor: theme.color.themeBlue6,
						}}
						customIcon={
							<ButtonIconWrapper>
								<IconWrapper
									size={20}
									style={{
										marginTop: '5px',
									}}
								>
									<Icon glyph={DownloadIcon} />
								</IconWrapper>
							</ButtonIconWrapper>
						}
						isLoading={isPDF}
						onClick={() => {
							setIsPDF(true)
							setTimeout(() => {
								handlePrintToPDF()
							}, 50)
						}}
					/>
				</PdfDownloadButtonWrapper>
			</PdfDownloadButtonContainer>
		)
	}

	const orgDetails = mapRecord
		.filter(f => {
			const { orgTypeID = '' } = f

			return canShowDeforestation.length > 0
				? canShowDeforestation.includes(orgTypeID)
				: true
		})
		.reduce((accumulator, o) => {
			const existingOrg = accumulator.find(
				entry => entry.value === o.orgID
			)

			if (!existingOrg) {
				accumulator.push({
					label: o.name,
					value: o.orgID,
					orgTypeID: o.orgTypeID,
					address: o.locationOfTrace,
				})
			}

			return accumulator
		}, [])

	const getCertificateIcon = (item, orgInfo, mapRecordData = {}) => {
		let icon = getIn(orgInfo, ['eudrComplianceData', item])
			? verifiedIcon
			: NotVerifiedIcon
		const alers = getIn(orgInfo, ['deforestationAlertData']) || []
		if (item === 'deforestationAlert') {
			icon = (
				<div
					style={{
						cursor: 'pointer',
					}}
					title={t('deforestationAlert.showDeforestationAlerts')}
					role="button"
					tabIndex={0}
					onKeyDown={console.log('')}
					onClick={() => {
						const orgDetails =
							mapRecordData?.length > 0 &&
							mapRecordData
								?.filter(f => {
									const { orgID = '' } = f

									return orgID === orgInfo?.orgID
								})
								.reduce((accumulator, o) => {
									const existingOrg = accumulator.find(
										entry => entry.value === o.orgID
									)

									if (!existingOrg) {
										accumulator.push({
											label: o.name,
											value: o.orgID,
											orgTypeID: o.orgTypeID,
											address: o.locationOfTrace,
										})
									}

									return accumulator
								}, [])
						setSelectedDeforestOrg(orgDetails)
						dispatch(
							TraceDuc.creators.getDeforestationEvents(
								orgInfo?.orgID
							)
						)
						setShowDeforestationModal(true)
					}}
				>
					<CircleBox
						color={theme.color.white}
						fontSize={10}
						fontWeight={600}
						width={17}
						height={17}
						backgroundColor={
							alers.length > 0
								? theme.color.pureRed
								: theme.color.iconGreen
						}
					>
						{alers.length}
					</CircleBox>
				</div>
			)
		}

		return icon
	}

	const getCertificateIconColor = (item, orgInfo) => {
		let icon = getIn(orgInfo, ['eudrComplianceData', item])
			? theme.color.themeGreen4
			: '#D05858'

		if (item === 'deforestationAlert') {
			icon = getIn(orgInfo, ['eudrComplianceData', item])
				? '#D05858'
				: theme.color.themeGreen4
		}

		return icon
	}

	const getEUDRDetails = mapRecordData => {
		const details = []
		const showField = canShowDeforestation.includes(
			getIn(orgInfo, ['orgType'])
		)

		EUDR_COMPLIANCE_LIST_FOR_SUMMARY.forEach(item => {
			details.push(
				<>
					{(!(
						item === 'deforestationAlert' || item === 'geoJsonFlag'
					) ||
						showField) && (
						<div style={{ display: 'flex' }}>
							<KeyValueSegment
								name={t(EUDR_COMPLIANCE_LIST_LABELS[item])}
								detail={
									<div>
										{item === 'deforestationAlert' ? (
											<div
												style={{
													marginLeft: '9px',
												}}
											>
												{getCertificateIcon(
													item,
													orgInfo,
													mapRecordData
												)}
											</div>
										) : (
											<IconWrapper
												size={20}
												color={getCertificateIconColor(
													item,
													orgInfo
												)}
												style={{
													marginTop: '-2px',
													marginLeft: '8px',
													marginBottom: '-3px',
												}}
											>
												<Icon
													glyph={getCertificateIcon(
														item,
														orgInfo
													)}
												/>
											</IconWrapper>
										)}
									</div>
								}
								sub
							/>
						</div>
					)}
				</>
			)
		})

		return details
	}

	return (
		<>
			<Modal
				closeable
				size="xlarge"
				show={showMap}
				heading={t('productTrace.map')}
				body={
					<Box height="400px">
						<AreaMapPreview
							geoJSON={{
								type: 'FeatureCollection',
								features: [
									{
										type: 'Feature',
										properties: {},
										geometry: {
											type: 'Polygon',
											coordinates: [
												getIn(polygonData, [
													'shape',
													'coordinates',
												]),
											],
										},
									},
								],
							}}
						/>
					</Box>
				}
				hideButtons
				onConfirm={() => {
					setShowMap(false)
				}}
				onClose={() => {
					setShowMap(false)
				}}
			/>

			<Modal
				closeable
				heading={
					<P
						bold
						color={theme.color.primary}
						large
					>{`${entityNumber}, ${new Date(
						entityIssuedDate
					).toDateString()}, ${productName}`}</P>
				}
				forceCloseviaButton
				size="100%"
				show={showDeforestationModal}
				hideButtons
				full
				extendStylesBody={{
					display: 'flex',
					flex: 1,
					overflow: 'hidden',
				}}
				body={
					<TraceDeforestationEvents
						deforestationOrgCount={deforestationOrgCount}
						treeData={treeRecord}
						eventsArray={deforestationEvents}
						orgDetails={orgDetails}
						selectedDeforestOrg={selectedDeforestOrg}
						onOrgSelection={val => {
							setSelectedDeforestOrg(val)
							const value =
								val.map(d => d.value)?.toString() || ''
							dispatch(
								TraceDuc.creators.getDeforestationEvents(value)
							)
						}}
						t={t}
					/>
				}
				onClose={() => {
					setShowDeforestationModal(false)
				}}
			/>

			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				{loadingState && <TransparentBgSpinner />}
				<Spacer size={16} />
				<Box row justifyContent="space-between" alignItems="baseline">
					<Box>
						<Heading>{t('productTrace.title')}</Heading>
						<Box row alignItems="center" width>
							<Box>
								<P>{`${t(
									'productTrace.showingTraceOf'
								)} ${partnerName} ${t(
									'productTrace.forConsgmt'
								)}`}</P>
							</Box>
							<ButtonWithNoBorder
								type="submit"
								extendStyles={{ padding: '0px 5px	' }}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
											{
												rootModule: 'delivery-order',
												documentReference: entityOrg,
												documentStatus: null,
											},
											{},
											{},
											true
										)
									)
								}
								label={entityNumber}
							/>
						</Box>

						<P>{`${t('productTrace.product')}: ${productName} & ${t(
							'productTrace.srcdQty'
						)}: ${quantity}.`}</P>
					</Box>
					<Box style={{ padding: 2 }} width="200px">
						<Button
							label={t('common.back')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types.PRODUCT_TRACE
									)
								)
							}
						/>
					</Box>
				</Box>

				<Spacer size={32} />
				<BoxShadowWrapper style={{ padding: '20px' }}>
					{getSummaryBlock()}
					{(activeStatus === 'trace-summary' ||
						activeStatus === 'trace-tree' ||
						activeStatus === 'trace-graph') && (
						<>
							{getCertificateType()}
							{getSupplyChainType()}
						</>
					)}
				</BoxShadowWrapper>
				<Spacer size={4} />
				<BoxShadowWrapper id="viewOrgList">
					<Box row justifyContent="space-between">
						{getTabButtons()}
						{activeStatus !== 'trace-tree' &&
							activeStatus !== 'trace-summary' && (
								<FullScreen icon={FullScreenIcon}>
									{activeStatus === 'trace-graph' && (
										<>
											<BoxShadowWrapper
												style={{ padding: '20px' }}
											>
												{getSummaryBlock()}
												{getCertificateType()}
												{getSupplyChainType()}
											</BoxShadowWrapper>
											{getTraceGraph(true)}
										</>
									)}
									{activeStatus === 'trace-map' && (
										<Box
											style={{
												overflow: 'auto',
											}}
										>
											<BoxShadowWrapper
												style={{ padding: '20px' }}
											>
												{getSummaryBlock()}
											</BoxShadowWrapper>
											{coordinates.length > 0
												? getTraceMap()
												: getMapNotAvailable()}
										</Box>
									)}
								</FullScreen>
							)}
					</Box>
					<Box
						row
						width="100%"
						padding="4px 15px"
						style={{
							justifyContent: 'center',
							borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
						}}
					>
						<Box
							flex="auto"
							padding="20px 15px"
							width={activeStatus === 'trace-tree' ? 600 : 'auto'}
							height="100%"
							style={{
								overflow: 'scroll',
								position: 'relative',
							}}
						>
							{activeStatus === 'trace-summary' && (
								<Box style={{ overflow: 'auto' }} padding={8}>
									{getTraceSummary()}
								</Box>
							)}
							{activeStatus === 'trace-tree' && (
								<Box style={{ overflow: 'auto' }} padding={8}>
									{getTraceTree()}
								</Box>
							)}
							{isPDF && (
								<PdfBaseContainer>
									<PdfContentContainer ref={componentRef}>
										<PdfDataContainer>
											<Box>
												{getTitleForPDF()}
												{getSubTitleForPDF()}
											</Box>
											<PdfBoxShadowContainer>
												<BoxShadowWrapper
													style={{
														padding: '20px',
														width: '730px',
													}}
												>
													{activeStatus ===
														'trace-tree' && (
														<>
															<Box>
																{getCertificateType(
																	true
																)}
															</Box>
															<Box>
																{getSupplyChainType(
																	true
																)}
															</Box>
														</>
													)}
												</BoxShadowWrapper>
											</PdfBoxShadowContainer>
										</PdfDataContainer>
										<PdfTreeContainer>
											<PdfTreeHeader>
												{t('productTrace.tree')}
											</PdfTreeHeader>
											<PdfTree>
												{getTraceTree(true)}
											</PdfTree>
										</PdfTreeContainer>
									</PdfContentContainer>
								</PdfBaseContainer>
							)}
							{activeStatus === 'trace-graph' && getTraceGraph()}
							{activeStatus === 'trace-map' && (
								<>
									<Spacer size={12} />

									<Box
										style={{ overflow: 'auto' }}
										padding={8}
									>
										{coordinates.length > 0
											? getTraceMap()
											: getMapNotAvailable()}
									</Box>
								</>
							)}
						</Box>
						{(activeStatus === 'trace-tree' ||
							activeStatus === 'trace-summary') &&
							nodeHasDetail && (
								<SegmentSeparatorWrapper>
									<SegmentSeparatorLine />
								</SegmentSeparatorWrapper>
							)}
						{(activeStatus === 'trace-tree' ||
							activeStatus === 'trace-summary') &&
							nodeHasDetail && (
								<Box
									flex="auto"
									padding="20px 15px"
									width={600}
								>
									{activeStatus === 'trace-tree' &&
										getDownloadButton()}
									<Box>
										<Box style={{ marginBottom: 24 }}>
											{getOrgInfo()}
										</Box>
										<BoxShadowWrapper
											style={{ marginBottom: 24 }}
										>
											<Box
												padding={8}
												style={{
													backgroundColor:
														theme.color.accent3,
												}}
											>
												<P bold color="#263b97">
													{t(
														'productTrace.certificationStatus'
													)}
												</P>
											</Box>
											{currentCertificates &&
												currentCertificates.length >
													0 && (
													<Box padding={8}>
														{currentCertificates.map(
															certificate => {
																return !(
																	certificate.key ===
																		'UNCERTIFIED' ||
																	certificate.key ===
																		'ANY' ||
																	certificate.key ===
																		'EUDR'
																) ? (
																	<KeyValueSegment
																		name={
																			certificate.label
																		}
																		detail={
																			certType.find(
																				item =>
																					item.ctype ===
																					certificate.key
																			)
																				? `${
																						certType.find(
																							item =>
																								item.ctype ===
																								certificate.key
																						)
																							.state
																				  } (${t(
																						'productTrace.expiryOn'
																				  )} ${moment(
																						certType.find(
																							item =>
																								item.ctype ===
																								certificate.key
																						)
																							.expiryEndDate
																				  ).format(
																						'DD/MM/YYYY'
																				  )})`
																				: t(
																						'common.notAvailable'
																				  )
																		}
																	/>
																) : (
																	certificate.key ===
																		'EUDR' && (
																		<>
																			<KeyValueSegment
																				name={t(
																					'productTrace.EUDRCompliance'
																				)}
																				detail={
																					<IconWrapper
																						size={
																							20
																						}
																						color={
																							getIn(
																								orgInfo,
																								[
																									'eudrComplianceData',
																									'eudrFlag',
																								]
																							)
																								? theme
																										.color
																										.themeGreen4
																								: '#D05858'
																						}
																						style={{
																							marginTop:
																								'-2px',
																							marginLeft:
																								'8px',
																							marginBottom:
																								'-3px',
																						}}
																					>
																						<Icon
																							glyph={
																								getIn(
																									orgInfo,
																									[
																										'eudrComplianceData',
																										'eudrFlag',
																									]
																								)
																									? verifiedIcon
																									: NotVerifiedIcon
																							}
																						/>
																					</IconWrapper>
																				}
																			/>
																			<div
																				style={{
																					marginLeft:
																						'20px',
																				}}
																			>
																				{getEUDRDetails(
																					certificate,
																					orgInfo,
																					mapRecord
																				)}
																			</div>
																		</>
																	)
																)
															}
														)}
													</Box>
												)}
										</BoxShadowWrapper>

										{isNeeded &&
											transformingDocuments.length > 0 &&
											getTransformingDocuments()}
										{isNeeded &&
											transformedDocuments.length > 0 &&
											getTransformedDocuments()}
										{currentDeforestationData &&
											currentDeforestationData.length >
												0 && (
												<>
													<Spacer size={24} />
													<Box
														row
														justifyContent="space-between"
													>
														<Label
															style={{
																fontWeight:
																	'bold',
															}}
														>
															{t(
																'productTrace.deforestationData'
															)}
														</Label>
													</Box>
													<Box
														row
														justifyContent="space-between"
													>
														<Label>
															{t(
																'productTrace.deforestationInfo',
																{ orgName }
															)}
														</Label>
													</Box>
													<Spacer size={8} />

													<BoxShadowTable
														columnConfig={traceDeforestationDataTableConfig(
															t
														)}
														rowData={
															currentDeforestationData
														}
														loading={loadingState}
													/>
												</>
											)}
									</Box>
								</Box>
							)}
					</Box>
					{activeStatus === 'trace-graph' && (
						<Box flex="auto" padding="20px 15px">
							{getOrgInfo()}
							{isNeeded &&
								transformingDocuments.length > 0 &&
								getTransformingDocuments()}

							{isNeeded &&
								transformedDocuments.length > 0 &&
								getTransformedDocuments()}
						</Box>
					)}
				</BoxShadowWrapper>
			</Box>
		</>
	)
}

export { GenerateTrace }
